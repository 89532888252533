let switchBtn = document.querySelector(".button-switch-lang");

switchBtn.addEventListener("click", (e) => {
  e.stopPropagation();
  switchBtn.classList.toggle("open");
});

window.addEventListener("click", () => {
  if (switchBtn.classList.contains("open")) switchBtn.classList.remove("open");
});
